class Charter {
	static _hoursToHours(a) {
		return a;
	}

	static _hoursToDays(a) {
		return Math.ceil(a / 24);
	}

	static _hoursToWeeks(a) {
		return Math.ceil(a / 24 / 7);
	}

	static renderDayChartWithHours(pointData, el) {
		window.requestAnimationFrame(function () {
			Charter._renderChart(pointData, el, -24, 0, Charter._hoursToHours);
		});
	}

	static renderWeekChartWithHours(pointData, el) {
		window.requestAnimationFrame(function () {
			Charter._renderChart(pointData, el, -24 * 7, 0, Charter._hoursToHours);
		});
	}

	static renderMonthChartWithDays(pointData, el) {
		window.requestAnimationFrame(function () {
			Charter._renderChart(pointData, el, -30, 0, Charter._hoursToDays);
		});
	}

	static render3MonthChartWithDays(pointData, el) {
		window.requestAnimationFrame(function () {
			Charter._renderChart(pointData, el, -30 * 3, 0, Charter._hoursToDays);
		});
	}

	static render6MonthChartWithWeeks(pointData, el) {
		window.requestAnimationFrame(function () {
			Charter._renderChart(pointData, el, -4 * 6, 0, Charter._hoursToWeeks);
		});
	}

	static render1YearChartWithWeeks(pointData, el) {
		window.requestAnimationFrame(function () {
			Charter._renderChart(pointData, el, -4 * 12, 0, Charter._hoursToWeeks);
		});
	}

	static _renderChart(pointData, el, minOffset, maxOffset, offsetTransform) {
		$(el).empty();

		if (pointData.length === 0) {
			return;
		}

		// store to local variable
		const localData = pointData.map(item => [].concat(item));

		localData.forEach(item => item[0] = offsetTransform(item[0]));
		localData.sort(function (a, b) {
			return a[0] - b[0];
		});

		const chartRangeMinX = minOffset - localData.first()[0];
		const chartRangeMaxX = chartRangeMinX + (maxOffset - minOffset);

		const bars = [];
		let pointDataItemLast = null;
		do {
			const pointDataItem = localData.shift();
			if (pointDataItemLast !== null) {
				for (let diff = pointDataItem[0] - pointDataItemLast[0]; diff > 1; --diff)
					bars.push(pointDataItemLast[1]);
			}
			bars.push(pointDataItem[1]);
			pointDataItemLast = pointDataItem;
		} while (localData.length > 0);

		const sparklineOpts = {
			type: 'line',
			lineColor: App.theme.get('sparkline-blue-line-color'),
			fillColor: App.theme.get('sparkline-blue-fill-color'),
			width: '130px',
			height: '35px',
			lineWidth: App.theme.get('sparkline-line-width'),
			spotColor: false,
			maxSpotColor: false,
			minSpotColor: false,
			chartRangeMinX: chartRangeMinX,
			chartRangeMaxX: chartRangeMaxX,
			tooltipContainer: $('#tooltipc')
		};

		if (bars[bars.length - 1] > bars[0]) {
			sparklineOpts.lineColor = App.theme.get('sparkline-green-line-color');
			sparklineOpts.fillColor = App.theme.get('sparkline-green-fill-color');
		}
		if (bars[bars.length - 1] < bars[0]) {
			sparklineOpts.lineColor = App.theme.get('sparkline-red-line-color');
			sparklineOpts.fillColor = App.theme.get('sparkline-red-fill-color');
		}

		$(el).sparkline(bars, sparklineOpts);
	}
}